<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal" :widthModal="1200">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          {{ $gettext('Histórico de Vendas') }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-container fluid class="pb-0">
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  <!-- Periodo: {{ rangeDate.dtInicial | data('DD/MM/YYYY') }} a {{ rangeDate.dtFinal | data }} -->
                  <strong>Data de Referência:</strong> {{ data }}
                </v-col>

                <v-col class="text-right pa-3 pt-0 pb-1">
                  <strong>Revendedor:</strong> {{ revendedor }} 
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  <date />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-container>

      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>

      <v-container fluid class="pt-0"  v-else>
        <v-col>
          <div class="box-table">
            <TabelaHistoricoVendas :data="transmissoes.data" :hasMonthOrDate="hasMonthOrDate" />
          </div>
        </v-col>
      </v-container>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import date from '@/views/components/date'
import { formatDate } from '@/core/service/utils'

import moment from 'moment'

const namespaceStore = 'historicoVendas'

export default {
  name: 'ModalHistoricoVendasGecom',
  components: {
    TabelaHistoricoVendas: () => import('@/views/components/tabelaHistoricoVendas'),
    Modal: () => import('@/views/components/modal'),
    date
  },
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'Data mov.', value: 'date' },
      { align: 'start', class: 'table-header', text: 'Vendas', value: 'total' },
      { align: 'start', class: 'table-header', text: '(-) Comissão', value: 'commission' },
      { align: 'start', class: 'table-header', text: 'Líquido', value: 'liquid' },
      { align: 'start', class: 'table-header', text: 'Prêmios', value: 'prizes' },
      { align: 'start', class: 'table-header', text: 'Caixa', value: 'credit' },
      { align: 'start', class: 'table-header', text: 'PGTO', value: 'debit' }
    ],

    loading: false,
    loadingTable: false,
    entity: {},
    entityRequest: {},
    dtFinal: '',
    dtInicial: '',
    inativos: null,
    hasMonthOrDate: 1,
    activeModal: false,
    data: '',
    revendedor: '',
    loadingModal: false
  }),

  watch: {
    hasMonthOrDate (val) {
      this.setHasMonthOrDate(val)
    },

    transmissoes (val) {
      this.entityRequest = val.entity
    }
  },

  mounted () {
    Events.$on('modal::historicoVendas::historicoVendas', (e) => {
      this.activeModal = true
      this.loadingModal = true

      this.data = `${formatDate(moment(e.dtStart).startOf('month'), 'DD/MM/YYYY')} à ${formatDate(moment(e.dtEnd).endOf('month'), 'DD/MM/YYYY')}`
      this.revendedor = e.entity_cod

      if (e.hasMonthOrDate === 2) {
        e.dtStart = moment(e.dtStart, 'YYYY-MM').startOf('month').format('YYYY-MM-DD')
        e.dtEnd = moment(e.dtEnd, 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
      }


      this.getFilterVendas({ dtInicial: e.dtStart, dtFinal: e.dtEnd, entityId: e.entityId })
        .finally(() => {
          this.loadingModal = false
        })

    })
  },

  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'transmissoes']),
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'getFilterVendas', 'setHasMonthOrDate']),

    init() {},

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    }
  },

  beforeDestroy () {
    Events.$off('modal::historicoVendas::historicoVendas')
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';
</style>